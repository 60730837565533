import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../components/contact-info/contact-info";
import SEO from "../../components/seo/seo";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import showMktoForm from "../../helpers/showMktoForm";
import Button from "../../components/custom-widgets/button";
import List from "../../components/custom-widgets/list";
import TwoColumnSectionWithImage from "../../components/custom-widgets/two-column-section-with-image";
import BestBanksDefault from "../../components/best-banks/best-banks-default";

const OwnerOccupiedRealEstateFinancing = () => {
  const title = "Owner-Occupied Real Estate Loans";
  const description =
    "Learn about owner-occupied real estate loans at WaFd Bank. Our loan structure can be customized to fit your needs. Talk to a commercial banker today.";
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/owner-occupied-real-estate-financing/hero-commercial-oo-real-estate-01122023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/owner-occupied-real-estate-financing/hero-commercial-oo-real-estate-01122023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/owner-occupied-real-estate-financing/hero-commercial-oo-real-estate-01122023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/owner-occupied-real-estate-financing/hero-commercial-oo-real-estate-01122023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/owner-occupied-real-estate-financing/hero-commercial-oo-real-estate-01122023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/owner-occupied-real-estate-financing/hero-commercial-oo-real-estate-01122023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/owner-occupied-real-estate-financing/hero-commercial-oo-real-estate-01122023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/owner-occupied-real-estate-financing"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-commercial-oo-real-estate-01122023.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "owner-occupied-real-estate-financing-hero",
    ...getHeroImgVariables(imgData),
    altText: "Construction worker and architect talking in building under construction.",
    chevronBgClass: "bg-info",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Your Business Has Grown, So Should Your Space"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Owner-Occupied Real Estate Financing"
    }
  ];

  const featuresListData = {
    accountName: "features-list",
    items: [
      {
        id: 1,
        text: "Loan structure can be customized to fit your goals for the property and your ownership group"
      },
      {
        id: 2,
        text: "Adjustable and fixed rates available or take advantage of an interest rate swap to mitigate long-term rising rate risk"
      },
      {
        id: 3,
        text: "Single-close construction to permanent loans to help you build the perfect property for your growing business"
      },
      {
        id: 4,
        text: "Loan-to-Value (LTV) varies from 65 to 90%. Contact a Commercial Banker for details"
      },
      {
        id: 5,
        text: "Combine with a Line of Credit or Treasury Management services to get the most from your banking relationship"
      },
      {
        id: 6,
        text: "Extended loan terms are available to meet your borrowing needs"
      }
    ]
  };

  const FeaturesContent = () => {
    return (
      <>
        <h4 className="text-success">
          As your business grows, so do your facility and real estate needs. Commercial real estate loans help
          businesses finance the purchase of new or existing real estate holdings. If you need to build from scratch or
          remodel, we've got the construction financing experience you're looking for.
        </h4>
        <List {...featuresListData} />
        <Button
          id={"features-cta-contact-us"}
          class={"btn-primary no-min-width w-100 w-sm-auto"}
          onClick={() => showMktoForm(1067)}
          showIcon={false}
          type={"button"}
          text={"Contact Us"}
        />
      </>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1 className="mb-0">Owner-Occupied Real Estate Financing</h1>
      </section>
      <TwoColumnSectionWithImage contentOnLeft={false}>
        {{
          image: (
            <StaticImage
              src="../../images/thumbnail-real-estate-financing-022824.jpg"
              alt="Real estate agent and businessman going over building plans."
              placeholder="blurred"
              quality="100"
              className="border-radius-12 mb-3"
            />
          ),
          content: <FeaturesContent />
        }}
      </TwoColumnSectionWithImage>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default OwnerOccupiedRealEstateFinancing;
